<template>
	<v-dialog
		:value="pastApproval"
		transition="scale-transition"
		persistent
		max-width="800"
	>
		<v-card>
			<v-card-title>
				<div>
					<v-icon color="green">mdi-check-bold</v-icon>
					<span class="px-2">Past Approved Leaves:</span>
					<span v-if="list" class="font-bold">{{list[0]["requested_by_name"]}}</span>
				</div>
				<v-spacer/>
				<v-btn icon
					@click="$store.dispatch('closeApprovalDialog')"
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-divider class="mb-4" />
			<v-card-text v-if="list">
				<v-data-table
					:headers="headings"
					:items="list"
					:items-per-page="5"
					class="elevation-1"
				>
					<template #item.leave_type="{item}">
						<v-chip dark :color="findType(item).color">{{findType(item).name}}</v-chip>
					</template>
					<template #item.from_date="{item}">
						{{$formatUnix(item.from_date)}}
					</template>
					<template #item.to_date="{item}">
						{{$formatUnix(item.to_date)}}
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	name: "PastApprovedLeaves",
	data: () => ({
		headings: [
			{text: "Reason", value: "reason", sortable: false},
			{text: "Type", value: "leave_type", sortable: false, align: "center"},
			{text: "From Date", value: "from_date"},
			{text: "To Date", value: "to_date"},
		],
	}),
	computed: {
		...mapGetters({
			pastApproval: "pastApproval",
			list: "approvalList"
		})
	},
	created() {
	},
	methods: {
		findType(item) {
			return this.$helper.LEAVE_TYPES.find(type => type.code === item.leave_type)
		},
	}
}
</script>

<style scoped lang="scss">

</style>
